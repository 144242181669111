<!--商品-->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label></label>
            <el-button type="primary" block size="mini" @click="goBack()">返回</el-button>
            <div class="flex flex-ai-c" style="marginLeft:auto">
                <el-radio-group v-model="radio" style="margin-right:10px">
                    <el-radio label="year">年</el-radio>
                    <el-radio label="month">月</el-radio>
                    <el-radio label="week">周</el-radio>
                </el-radio-group>

                <el-date-picker v-model="inputyear" size="mini" type="year" :clearable="false" value-format="yyyy" format="yyyy年" placeholder="选择年" @change="getData()" v-show="radio=='year'">
                </el-date-picker>

                <el-date-picker v-model="inputmonth" size="mini" type="month" :clearable="false" value-format="yyyy-MM-dd" format="yyyy年MM月" placeholder="选择月" @change="getData()" v-show="radio=='month'">
                </el-date-picker>

                <el-date-picker v-model="inputweek" size="mini" type="week" :clearable="false" value-format="yyyy-MM-dd" format="yyyy 第 WW 周" placeholder="选择周" @change="getData()" v-show="radio=='week'">
                </el-date-picker>
            </div>
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 160px );overflow-y:auto">
        <el-row :gutter="10" style="height:100%">
            <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14" style="height:100%;marginBottom:20px;">
                <div class="t1">
                    <div id="bar" :style="{width: '98%', height: '40%',marginTop:'5%'}"></div>
                    <div id="line" :style="{width: '98%', height: '40%',marginTop:'7%'}"></div>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10" style="height:100%;marginBottom:20px;">
                <div class="t2">
                    <div id="pie" :style="{width: '98%', height: '90%',marginTop:'5%'}"></div>
                </div>
            </el-col>
        </el-row>
    </div>

</div>
</template>

<script>
var echarts = require('echarts');
export default {
    data: function() {
        return {
            list: [],

            radio: 'month', //年月周-默认周
            input: '', //时间选择框
            inputyear: '',
            inputmonth: '',
            inputweek: '',
            inputquarter: '', //季度
            detail: {},
            goodsId:null,
            typeList: [],
            chartLegend:[]
        }
    },
    mounted: function() {
        this.init();
        window.onresize = () => { //  根据窗口大小调整曲线大小
            let myChart = echarts.init(document.getElementById('pie'));
            myChart.resize();
            let myChartbar = echarts.init(document.getElementById('bar'));
            myChartbar.resize();
            let myChartline = echarts.init(document.getElementById('line'));
            myChartline.resize();
        };
    },
    destroyed() {
        window.onresize = null;
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                let myChart = echarts.init(document.getElementById('pie'));
                myChart.resize();
                let myChartbar = echarts.init(document.getElementById('bar'));
                myChartbar.resize();
                let myChartline = echarts.init(document.getElementById('line'));
                myChartline.resize();
            }, 200)
        },
        'radio': function() {
            if (this.radio == 'month') {
                var myDate = new Date();
                var currentMonth1 = myDate.getMonth();
                var firstDay2 = new Date(myDate.getFullYear(), currentMonth1, 1)
                this.inputmonth = this.dataFormat(firstDay2);
            } else if (this.radio == 'week') {
                var current = new Date();
                var currentyear = current.getFullYear();
                var currentMonth2 = current.getMonth();
                var currentDay = current.getDay();
                var currentDate = current.getDate();
                var firstweek = new Date(currentyear, currentMonth2, currentDate - currentDay + 1);
                this.inputweek = this.dataFormat(firstweek);
            } else if (this.radio == 'year') {
                var myDate2 = new Date();
                this.inputyear = String(myDate2.getFullYear());
            } else if (this.radio == 'quarter') {
                var date2 = new Date();
                var nowYear = date2.getFullYear();
                var nowMonth = date2.getMonth(); //当前月
                var quarterStartMonth = 0;
                if (nowMonth < 3) {
                    quarterStartMonth = 1;
                } else if (2 < nowMonth && nowMonth < 6) {
                    quarterStartMonth = 2;
                } else if (5 < nowMonth && nowMonth < 9) {
                    quarterStartMonth = 3;
                } else if (nowMonth > 8) {
                    quarterStartMonth = 4;
                }
                this.defaultValue = nowYear + '-' + quarterStartMonth;
                this.inputquarter = nowYear + '-' + quarterStartMonth;
                this.bus.$on('quarterchange', (value) => {
                    this.inputquarter = value;
                    this.getData();
                })
            }
            this.getData();
        }
    },
    methods: {
        init: function() {
            if (this.radio == 'month') {
                var myDate = new Date();
                var currentMonth3 = myDate.getMonth();
                var firstDay2 = new Date(myDate.getFullYear(), currentMonth3, 1)
                this.inputmonth = this.dataFormat(firstDay2);
                this.getData();
            }
        },
        goBack(){
            this.$router.go(-1)
        },
        // 类型
        getType() {
            this.http.post('/admin.category/getList',{},false).then(re => {
                this.typeList = re.data
            })
        },
        getData() {
            let radio;
            var input
            if (this.radio == 'year') {
                input = this.inputyear;
                radio = 3
            } else if (this.radio == 'month') {
                input = this.inputmonth;
                radio = 2
            } else if (this.radio == 'week') {
                input = this.inputweek;
                radio = 1
            } else if (this.radio == 'quarter') {
                input = this.inputquarter
            }
            this.http.post('/admin.statistics/userAnalysis', {
                user_id:this.$route.query.id,
                type: radio,
                date: input,
            }).then(re => {
                this.detail = re.data;
                for (var i in re.data.line.yAxis) {
                    re.data.line.yAxis[i]['type'] = 'line';
                    re.data.line.yAxis[i]['smooth'] = 'true';
                    re.data.line.yAxis[i]['data'] = re.data.line.yAxis[i].value;
                    this.chartLegend.push(re.data.line.yAxis[i].name)
                }
                this.list = re.data.line.yAxis;
                this.drawBar();
                this.drawPie();
                this.drawLine();
            })
        },

        drawBar() {
            var chartpro = echarts.init(document.getElementById('bar'));
            chartpro.setOption({
                title: {
                    text: this.detail.barChart.name,
                    // subtext: '纯属虚构',
                    left: 'center'
                },
                xAxis: {
                    name: '时间',
                    type: 'category',
                    data: this.detail.barChart.xAxis
                },
                yAxis: {
                    type: 'value',
                    name: '金额（元）'
                },
                grid: { //位置
                    left: '3%',
                    right: '40px',
                    bottom: '3%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                series: [{
                    data: this.detail.barChart.yAxis,
                    type: 'bar',
                    name: '金额'
                }]
            })
        },
        drawPie() {
            var pie = echarts.init(document.getElementById('pie'));
            pie.setOption({
                title: {
                    text: '各品类消费统计',
                    // subtext: '纯属虚构',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: '20px',
                    top: '20px',
                    type: 'scroll',
                },
                series: [{
                    name: '消费统计',
                    type: 'pie',
                    radius: '50%',
                    top: '5%',
                    data:this.detail.pieChart.data,
                    // data: [{
                    //         value: 1048,
                    //         name: '搜索引擎'
                    //     },
                    //     {
                    //         value: 735,
                    //         name: '直接访问'
                    //     },
                    // ],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }]
            })
        },
        drawLine(){
            var myChart = echarts.init(document.getElementById('line'));
            myChart.setOption({
                title: {
                    text: this.detail.line.name,
                    // subtext: '纯属虚构',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        // type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                        label: {
                            show: true
                        }
                    },
                },
                legend: {
                    left: 'right',
                    type: 'scroll',
                    top: '30',
                    height: '100',
                    data: this.chartLegend
                },
                color: ['#c23531', '#e6a23c', '#61a0a8'],
                grid: { //位置
                    left: '3%',
                    right: '40px',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    // axisLabel: {
                    //     interval: 0,
                    //     rotate: 60
                    // },
                    data: this.detail.line.xAxis,
                    name: '时间'
                },
                yAxis: {
                    type: 'value',
                    name: '金额（元）',
                },
                series: this.list
            });
        },
        dataFormat(time) {
            return `${time.getFullYear()}-${time.getMonth() + 1 >= 10 ? (time.getMonth() + 1) : '0' + (time.getMonth() + 1)}-${time.getDate() >= 10 ? time.getDate() : '0' + time.getDate()}`;
        },
    }
}
</script>
<style media="screen">
.ovtitle {
    font-size: 14px;
    color: #303133;
    border-bottom: 1px solid #EBEEF5;
    height: 45px;
    padding: 0 8px;
    display: flex;
    align-items: center;

    >img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
}

.t1,
.t2 {
    height: 100%;
    background: #fff;
    border: 1px #ddd solid;
    box-sizing: border-box;
    margin-bottom: 20px;
}
</style>
